//* Public Declare
$(window).on('load', function() {
    // Function for navbar hamburger
    $(document).on('click', 'nav .menu-col-mob .hamburger', function() {
        $("nav .menu-col-mob").toggleClass("open");
    });

    // handle links with @href started with '#' only
    $(document).on('click', 'a[href^="#"]', function(e) {
        // target element id
        var id = $(this).attr('href');

        // target element
        var $id = $(id);
        if ($id.length === 0) {
            return;
        }

        // prevent standard hash navigation (avoid blinking in IE)
        e.preventDefault();

        // top position relative to the document
        var pos = $id.offset().top;

        // animated top scrolling
        $('body, html').animate({scrollTop: pos});
    });

    // AOS Animate Initialize
    AOS.init({
        once: true
    });

    // Mini Masonry Initialize
    if($('div').hasClass('masonry_wrapper'))
    {
        if(window.matchMedia("(max-width: 767px)").matches)
        {
            var masonry = new MiniMasonry({
                container: document.querySelector('.masonry_wrapper'),
                surroundingGutter: false,
                ultimateGutter: 2,
                gutterX: 20,
                gutterY: 20,
                baseWidth: 150
            });
        }
        else
        {
            var masonry = new MiniMasonry({
                container: document.querySelector('.masonry_wrapper'),
                surroundingGutter: false,
                gutterX: 20,
                gutterY: 20
            });
        }
    }

    // Fancy Gallery
    $('.fancy').fancybox({
        'transitionIn'	:	'elastic',
        'transitionOut'	:	'elastic',
        'speedIn'		:	600,
        'speedOut'		:	200,
        'overlayShow'	:	false
    });

    // Owl Content
    var owl = $(".owl-content");
    owl.owlCarousel({
        margin: 20,
        responsive: {
            0: {
                items: 1
            },        
            600: {
                items: 1
            },
            1024: {
                items: 3
            },        
            1366: {
                items: 3
            }
        }
    });

    // $("#testi-owl").owlCarousel({
    //     loop: true,
    //     autoplay: false,
    //     margin: 20,
    //     responsiveBaseElement: 'body',
    //     responsiveClass: true,
    //     autoHeight: true,
    //     autoplayTimeout: 7000,
    //     smartSpeed: 800,
    //     nav: true,
    //     responsive: {
    //         0: {
    //             items: 1
    //         },        
    //         600: {
    //             items: 1
    //         },
    //         1024: {
    //             items: 3
    //         },        
    //         1366: {
    //             items: 3
    //         }
    //     }
    // });
    $(".next").click(function(){ owl.trigger('owl.next'); });
    $(".prev").click(function(){ owl.trigger('owl.prev'); });

    // Navbar Sticky Scroll Function
    if($('nav').hasClass('sticky-base'))
    {
        $(window).scroll(function() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > 150) 
            { 
                $('nav').removeClass('sticky');
            }
            else
            {
                $('nav').addClass('sticky');
            }
        });
    }
});