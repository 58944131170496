//*------- Function Groups -------//
    //* Contact Us
    function contact_us()
    {
        $('form[id="contactUsForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    name: {
                        required: true,
                        maxlength: 255
                    },
                    email: {
                        required: true,
                        email: true,
                        maxlength: 150
                    },
                    message: 'required'
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: $(form).serialize(),
                        cache: false,
                        processData: false,
                        beforeSend: function() {
                            $('#contactUsForm .btn-submit').html('<i class="fa fa-spinner fa-spin"></i>');
                            $('#contactUsForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){
                                $('#contactUsForm input[name=name], #contactUsForm input[name=email]').val('');
                                $('#contactUsForm textarea').val('');

                                $('#contactUsForm .btn-submit').html('Send Message');
                                $('#contactUsForm .btn-submit').prop('disabled', false);
                                $('.col-contact-form .alert-message-box').html('<div class="alert alert-success">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#contactUsForm .btn-submit').html('Send Message');
                            $('#contactUsForm .btn-submit').prop('disabled', false);

                            $('.col-contact-form .alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;
                },
            });
        });
    }